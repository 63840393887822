// Custom GER variables
$masters-color-blue : #0E4AB3;
$theme-blue : #0E4AB3;
$theme-gray: #a1a1a1;
$theme-gray-light: #EFEFEF;
$theme-gray-dark: #707070;
$theme-red : #EF6A65;
$font-roboto: Roboto, sans-serif;
$table-data-color: #707070;
$search-color:#a0a0a0;
$border-radius-1: 0.25rem;
$border-radius-2: 0.50rem;

$theme-table:#707070;
$theme-light-blue: #5377B5;
$radio-green: #C3DA8C;
$theme-font-size:11px;
